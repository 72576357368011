import "./App.css";
// import HomeRoute from "./routes/homeRoute";
// import ServicesRoute from "./routes/servicesRoute";
// import AboutUsRoute from "./routes/aboutUsRoute";
// import ContactsRoute from "./routes/contactsRoute";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { BackendProvider } from "./context/backendContext";

// import FloatingButton from "./components/floatingButton";
// import DistributorsRoute from "./routes/distributorsRoute";
// import ProductsRoute from "./routes/productsRoute";
import Contacts from "./components/contacts";
import UnderConstructionRoute from "./routes/underConstruction"

function App() {
  let margin = 0;
  const computeMargin = () => {
    if (window.innerWidth > 567) {
      margin = 60;
    } else {
      margin = 15;
    }
  };

  computeMargin();

  return (
    <BrowserRouter>
      <div
        className="App app-margin"
        style={{
          background: "#fff",
          // border: "2px solid red",
          marginLeft: margin + "px",
          marginRight: margin + "px",
        }}
      >
        <BackendProvider>
          <Routes>
            {/* <Route exact path="/" element={<HomeRoute />} />
            <Route exact path="/services" element={<ServicesRoute />} />
            <Route exact path="/aboutus" element={<AboutUsRoute />} />
            <Route exact path="/contacts" element={<ContactsRoute />} />
            <Route exact path="/distributors" element={<DistributorsRoute />} /> */}
            <Route exact path="/" element={<UnderConstructionRoute/>}/>
            {/* <Route
              exact
              path="/products/:productId"
              element={<ProductsRoute />}
            /> */}
          </Routes>
          {/* <FloatingButton link="https://wa.me/593995298554" /> */}
        </BackendProvider>
      </div>
      <Contacts />
    </BrowserRouter>
  );
}

export default App;
